import { checkAdmin as checkAdminApi, getMyPageInfo } from "./api.service";
const STORAGE_AUTH_KEY = "jellycrew.auth";

export const setAuth = data => {
  localStorage.setItem(STORAGE_AUTH_KEY, JSON.stringify(data));
};

export const getAuth = () => {
  let auth = null;

  const authJsonStr = localStorage.getItem(STORAGE_AUTH_KEY);
  if (authJsonStr) {
    auth = JSON.parse(authJsonStr);
  }

  return auth;
};

export const clearAuth = () => {
  localStorage.removeItem(STORAGE_AUTH_KEY);
};

export const checkAdmin = async () => {
  const my = await getMyPageInfo();
  if (!my || !my.data.data || !my.data.data.user_id)
    throw new Error("로그인 정보가 없습니다.");

  const isAdmin = await checkAdminApi(my.data?.data?.user_id);
  return isAdmin?.data;
};
