import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { getAuth } from "./auth.service";

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_CCCV_API_URL;
  },

  setHeader() {
    const auth = getAuth();

    if (auth.accessToken && auth.refreshToken) {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${auth.accessToken}`;
      Vue.axios.defaults.headers.common.refreshToken = auth.refreshToken;
    }
  },

  get(resource) {
    return Vue.axios.get(resource).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  },

  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },

  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  delete(resource) {
    return Vue.axios.delete(resource).catch(error => {
      throw new Error(`ApiService ${error}`);
    });
  }
};

export default ApiService;

export const TAG = "CCCV_JELLYCREW";
export const MAIN_NFT_TAG = "CCCV_JELLYCREW_MAIN";
export const COMINGSOON_CURATION_TITLE = "JELLYCREW_COMINGSOON";

export const getCreators = () => {
  return ApiService.get(`pages/tag/${TAG}`);
};

export const getPageInfo = pageId => {
  return ApiService.get(`pages/getPageAndSns?pageId=${pageId}`);
};

export const getKakaoAuthToken = code => {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", process.env.VUE_APP_KAKAO_REST_API_KEY);
  params.append("redirect_uri", process.env.VUE_APP_KAKAO_REDIRECT_URL);
  params.append("code", code);

  return ApiService.post(`https://kauth.kakao.com/oauth/token`, params, {
    headers: {
      "Content-type": "application/x-www-form-urlencoded;"
    }
  });
};

export const cccvLogin = (type, auth) => {
  return ApiService.post(`/auth/sign/${type}`, auth);
};

export const checkAdmin = id => {
  ApiService.setHeader();
  return ApiService.get(`/admins/isAdmin?user_id=${id}`);
};

export const getMyPageInfo = () => {
  ApiService.setHeader();
  return ApiService.get(`/pages/myPage`);
};

export const searchUsers = (keyword, limit, cursor = null) => {
  if (cursor) {
    return ApiService.get(`/pages/tag-p/${TAG}${cursor}`);
  }

  let query = "";
  if (keyword) query = `&query=${keyword}`;
  return ApiService.get(`/pages/tag-p/${TAG}?limit=${limit ?? 9}${query}`);
};

export const searchNfts = (keyword, limit, cursor = null) => {
  if (cursor) {
    return ApiService.get(`/nft/search${cursor}`);
  }

  let query = "";
  if (keyword) query = `&query=${keyword}`;
  return ApiService.get(`/nft/search?limit=${limit ?? 9}&tags=@${TAG}${query}`);
};

export const getNft = tokenId => {
  return ApiService.get(`/nft/token/${tokenId}`);
};

export const setJellycrewRole = id => {
  return ApiService.post(`/admins/pages/${id}/roles`, {
    roles: [TAG]
  });
};

export const removeJellycrewRole = id => {
  return ApiService.put(`/admins/pages/${id}/roles`, {
    roles: [TAG]
  });
};

export const getUserInfo = pageId => {
  return ApiService.get(`/pages/getPageAndSns?pageId=${pageId}`);
};

export const getNfts = () => {
  return ApiService.get(`/nft/tag/@${TAG}`);
};

export const getMainNfts = () => {
  return ApiService.get(`/nft/tag/@${MAIN_NFT_TAG}`);
};

export const registerMainNft = aergoGroupId => {
  return ApiService.patch(`/nft/${aergoGroupId}/tags`, {
    isAdd: true,
    tags: [`@${MAIN_NFT_TAG}`]
  });
};

export const removeMainNft = aergoGroupId => {
  return ApiService.patch(`/nft/${aergoGroupId}/tags`, {
    isAdd: false,
    tags: [`@${MAIN_NFT_TAG}`]
  });
};

export const getCurationListByTitle = title => {
  return ApiService.get(`/curation/title/${title}`);
};

export const createCurationList = (title, description, type) => {
  return ApiService.post("/curation", {
    title,
    description,
    type
  });
};

export const setComingsoonMember = (title, data) => {
  return ApiService.put(`/curation/title/${title}/items`, data);
};

export const registerStibee = email => {
  return ApiService.post(`/stibee/subscribe-api`, {
    name: email,
    email: email,
    apiKeyName: "HANDHUG_STIBEE_API_KEY",
    listId: process.env.VUE_APP_STIBEE_LIST_ID
  });
};
