<template>
  <nav class="navbar">
    <div class="container">
      <img class="logo" src="../assets/logo.png" @click="goToHome()" />
      <ul>
        <li @click="scrollToElement('creator-section')">Creator</li>
        <li @click="scrollToElement('coming-soon-section')">Coming Soon</li>
        <li @click="scrollToElement('popularity-section')">인기 NFT</li>
        <li @click="scrollToElement('take-a-look-section')">살펴보기</li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goToHome() {
      window.location.href = "/";
    },
    scrollToElement(name) {
      var el = document.querySelector(`#${name}`);
      if (el) el.scrollIntoView({ behavior: "smooth" });
    }
  }
};
</script>

<style scoped>
nav {
  background-color: white;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 860px;
}

.logo {
  width: calc(100vw * 200 / 1440);
  max-width: 200px;
  cursor: pointer;
}

ul {
  list-style: none;
}
ul > li {
  font-size: 15px;
  font-weight: 500;
  display: inline-block;
  margin-left: calc(100vw * 16 / 1440);
  cursor: pointer;
  color: #929292;
}

@media screen and (max-width: 860px) {
  nav {
    height: 80px;
  }

  ul {
    display: none;
  }

  .container {
    justify-content: center !important;
  }

  .logo {
    width: 170px;
    cursor: pointer;
  }
}
</style>
