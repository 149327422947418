<template>
  <footer>
    <img src="../assets/footer_logo.png" />
    <span class="copyright">Copyright &copy;HANDHUG. All rights reserved.</span>
    <span class="copyright"
      >Powered by &copy;BLOCKO XYZ inc. All rights reserved.</span
    >
    <span class="description">Jelly Crew World</span>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>

<style scoped>
footer {
  background-color: #ba7fd1;
  height: 230px;
  width: 100%;

  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

footer img {
  height: 20px;
  margin-bottom: 20px;
}

.copyright {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 13px;
  color: #f9f9f9;
}

.description {
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 500;
  color: #f9f9f9;
  font-style: italic;
}
@media screen and (max-width: 860px) {
  footer {
    width: 100%;
    height: 165px;
    align-items: flex-start;
  }

  footer img {
    height: 13px;
    margin-bottom: 10px;
    padding-left: 25px;
  }

  .copyright {
    font-size: 8px;
    padding-left: 25px;
  }

  .description {
    font-size: 12px;
    margin-top: 10px;
    font-weight: bold;
    padding-left: 25px;
  }
}
</style>
