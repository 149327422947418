import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  hash: false,
  routes: [
    {
      path: "/",
      component: () => import("@/views/Home")
    },
    {
      path: "/logout",
      component: () => import("@/views/auth/Logout")
    },
    {
      path: "/_o/kakao",
      component: () => import("@/views/auth/Kakao")
    },
    {
      path: "/_o/naver",
      component: () => import("@/views/auth/Naver")
    },
    {
      path: "/_o/google",
      component: () => import("@/views/auth/Google")
    },
    {
      path: "/admin",
      component: () => import("@/views/admin/Login")
    },
    {
      path: "/admin/user",
      name: "admin.user",
      component: () => import("@/views/admin/User")
    },
    {
      path: "/admin/nft",
      name: "admin.nft",
      component: () => import("@/views/admin/Nft")
    },
    {
      path: "/admin/comingsoon",
      name: "admin.comingsoon",
      component: () => import("@/views/admin/ComingSoon")
    }
  ]
});
