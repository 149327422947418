<template>
  <div id="app">
    <Header />
    <router-view></router-view>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer
  }
};
</script>

<style>
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Light.woff2)
      format("woff2"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Light.woff) format("woff"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Light.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Regular.woff) format("woff"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Regular.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Medium.woff) format("woff"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Medium.otf)
      format("opentype");
}

@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Bold.woff) format("woff"),
    url(assets/fonts/vendor/noto-sans-kr/NotoSansKR-Bold.otf) format("opentype");
}

body {
  font-family: "Noto Sans KR";
  margin: 0px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #ddd;
  padding: 8px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ba7fd1;
  color: white;
}

.table-btn {
  width: 50px;
  height: 30px;
  background-color: #ba7fd1;
  color: white;
  border: none;
  cursor: pointer;
}

.table-btn.danger {
  background-color: red;
  color: white;
}

.danger {
  color: red;
}
</style>
